import React from 'react'
import Layout from '../components/layout'

const NotFoundPage = () => (
  <Layout>
    <h1>Awkward...</h1>
    <p>It seems like you've stumbled upon a page that doesn't exist.</p>
    <h3>&lt;3</h3>
  </Layout>
)

export default NotFoundPage
